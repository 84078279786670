/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyC3AMDDQxNsUn1DBrjTz2-D8niweuEZNcQ",
  "appId": "1:400121262686:web:cbdf94c3c63a32cfd73acf",
  "authDomain": "schooldog-i-pierce-ga.firebaseapp.com",
  "measurementId": "G-BFMENX4X9M",
  "messagingSenderId": "400121262686",
  "project": "schooldog-i-pierce-ga",
  "projectId": "schooldog-i-pierce-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-pierce-ga.appspot.com"
}
